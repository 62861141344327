import React, { Suspense, lazy, useState } from 'react';
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import FeatureGrid from "@components/feature-grid";
import IntroArea from "@components/introarea/layout-1";
import ELPublishing from "@containers/elearning/elearning-publishing";
import IntroAreaReverse from "@components/introareareverse/layout-1";
import BoxSection from "@components/BoxSection/layout-one/layout-1";
import BoxSectionThree from "@components/BoxSection/layout-three/layout-2";
import ElearningProcess from "@containers/elearning/elearning-process";
import FaqArea from "@containers/faq/layout-03";
import CtaArea from "@containers/cta/layout-02";
import CaseStudy from "@containers/global/case-study/layout-01";
import PartnerArea from "@containers/partner/layout-01";
import { caseStudiesData } from "@constants";
import ContactArea from "@containers/contact-us";
import StickyMenu from "@containers/sticky-menu";
import QuoteForm from "@containers/translation-quote-form";
import UseScroll from "@containers/scroll";
const Footer = lazy(() => import("@layout/footer/layout-01"))
const InfotechnoPages = ({ location, data }) => {
  const [showRestComponents, setShowRestComponents] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);
  UseScroll((scrollTop) => {
    if (scrollTop > 30) {
      setShowRestComponents(true);
    } else {
      setShowRestComponents(false);
    }
  }, setIsMobile);
  return (
    <Layout location={location}>
      <Seo
        title="#1 eLearning Translation Services"
        description="Get high quality eLearning translation and localization in 200+ language pairs. We offer rigorous quality assurance. Get a Free Consultation."
      />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
          isMobile: isMobile
        }}
      />
      {
         !isMobile &&  <StickyMenu data={content["stycky-menu-data"]} setShowRestComponents={setShowRestComponents} showRestComponents={showRestComponents} />
      }
      <main className="site-wrapper-reveal">
        <PageHeader data={content["page-header-section"]} />
        <QuoteForm />
        {
          (!isMobile || (isMobile && showRestComponents)) &&<>
          <PartnerArea data={content["elearning-logo"]} />
          <FeatureGrid data={content["elearning-features"]} />
          <IntroArea layout={2} data={content["elearning-intro"]} />
          </>
        }
        {
          showRestComponents && <>
            <CtaArea data={content["cta-data"]} />
            <ELPublishing data={content["elearning-publishing"]} />
            <BoxSectionThree data={content["course-type"]} />
            <IntroAreaReverse data={content["elearning-adaptation-body"]} />
            <BoxSection layout={4} data={content["elearning-audio-body"]} />
            <ElearningProcess data={content["elearning-process-body"]} />
            <CaseStudy data={caseStudiesData} />
            <FaqArea data={content["efaq-section-two"]} />
            <ContactArea Layout={2} />
          </>
        }
      </main>
      {
        showRestComponents && <Suspense fallback={<div></div>}>
          <Footer data={{ ...data.site.siteMetadata }} />
        </Suspense>
      }
    </Layout>
  );
};

export const query = graphql`
  query elearningPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "elearning" }, pageType: { eq: "elearning-page" }) {
      content {
        ...PageContent
      }
    }
  }
`;

InfotechnoPages.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default InfotechnoPages;
